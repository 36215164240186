import React from "react";
import "./hexagonHome.css";

const HexagonBox = ({
	roundText,
	roundNumber,
	instructionText = "",
	content,
	isQuestion = false,
	isAnswer = false,
	onClick,
	disabled = false,
}) => {
	// Fixed width and height for the hexagon
	const width = 220;
	const height = 150;

	// Use fixed points for the hexagon
	const points = `
    ${width / 4},0 
    ${width * 0.75},0 
    ${width},${height / 2} 
    ${width * 0.75},${height} 
    ${width / 4},${height} 
    0,${height / 2}
  `;

	return (
		<div
			className={`hexagon-container ${
				isQuestion ? "question-hexagon" : ""
			} ${isAnswer ? "answer-hexagon" : ""}`}
			onClick={!disabled ? onClick : null}>
			<svg width={width} height={height} viewBox="0 -3 220 156">
				<polygon
					points={points}
					fill={disabled ? "#ccc" : "url(#gradient)"}
					stroke="#d4af37"
					strokeWidth="6"
					strokeLinejoin="miter"
				/>

				{/* For HomePage */}
				{!isQuestion && !isAnswer && (
					<>
						<text
							x="50%"
							y="25%"
							className="round-text"
							dominantBaseline="middle"
							textAnchor="middle">
							{roundText}
						</text>
						<text
							x="50%"
							y="50%"
							className="number-text"
							dominantBaseline="middle"
							textAnchor="middle">
							{roundNumber}
						</text>
						<text
							x="50%"
							y="82%"
							className="instruction-text"
							dominantBaseline="middle"
							textAnchor="middle">
							{instructionText}
						</text>
					</>
				)}

				{/* For QuizPage - Question */}
				{isQuestion && (
					<text
						x="50%"
						y="50%"
						className="question-text"
						dominantBaseline="middle"
						textAnchor="middle">
						{content}
					</text>
				)}

				{/* For QuizPage - Answer */}
				{isAnswer && (
					<text
						x="50%"
						y="50%"
						className="answer-text"
						dominantBaseline="middle"
						textAnchor="middle">
						{content}
					</text>
				)}
			</svg>

			{/* Define gradient in the SVG definitions */}
			<svg height="0" width="0">
				<defs>
					<linearGradient
						id="gradient"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%">
						<stop
							offset="0%"
							style={{ stopColor: "#c29445", stopOpacity: 1 }}
						/>
						<stop
							offset="50%"
							style={{ stopColor: "#dfcd77", stopOpacity: 1 }}
						/>
						<stop
							offset="100%"
							style={{ stopColor: "#c29445", stopOpacity: 1 }}
						/>
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};

export default HexagonBox;
