// PrivateRoute.jsx
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import authAPI from "../config/authAPI";
import "../App.css";

const PrivateRoute = ({ element, ...rest }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize as null to wait for authentication check.

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const response = await axios.get(authAPI.AUTH_URL, {
					withCredentials: true, // Include cookies in the request.
				});
				setIsAuthenticated(response.data.isAuthenticated);
			} catch (error) {
				setIsAuthenticated(false);
			}
		};
		checkAuth();
	}, []);

	// Render based on the authentication status.
	if (isAuthenticated === null) {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<div
					style={{
						borderTop: "12px solid #6a2a44",
						borderRight: "12px solid #6a2a44",
						borderBottom: "12px solid #d1b200",
						borderLeft: "12px solid #6a2a44",
						borderRadius: "50%",
						width: "100px",
						height: "100px",
						animation: "spin 2s linear infinite",
					}}
				/>
			</div>
		);
	}

	return isAuthenticated ? element : <Navigate to="/auth" />;
};

export default PrivateRoute;
