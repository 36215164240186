import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import homeAPI from "../../config/homeAPI";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HexagonButton from "../../components/Hexagons/Homepage/hexagonHome";
import Instruction from "../../components/Instructions/instruction";
import ResultCard from "../../components/Result Cards/resultQuiz";
import { toast } from "react-toastify";
import "./homePage.css";

const HomePage = ({ setRedirectedFromHome }) => {
	const [userData, setUserData] = useState(null);
	const [rounds, setRounds] = useState([]);
	const [showResultCard, setShowResultCard] = useState(false);
	const [showInstructions, setShowInstructions] = useState(false);
	const [selectedRoundId, setSelectedRoundId] = useState(null);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get(homeAPI.HOME_URL, {
					withCredentials: true,
				});
				setUserData(response.data.user);
				setRounds(response.data.rounds);
			} catch (error) {
				console.error("Error fetching data", error);
				showToast("Error fetching data", "error");
			}
		};
		fetchUserData();
	}, []);

	const showToast = (message, severity) => {
		toast(message, {
			type: severity,
			position: "top-right",
			autoClose: 3000,
			style: {
				backgroundColor: "#6a2a44",
				color: "#fff",
				width: "80%",
				top: 150, // Add top margin here
				right: "-20%",
			},
		});
	};

	const handleButtonClick = (round) => {
		if (round.round_name === "Super" && round.status === "not allowed") {
			showToast(round.message, "error");
			return;
		}
		if (round.status === "completed") {
			setSelectedRoundId(round.round_id);
			setShowResultCard(true);
			return;
		}
		if (round.action === "startGame") {
			setShowInstructions(true);
			setSelectedRoundId(round.round_id);
		} else if (round.action === "noAction") {
			showToast(
				round.message,
				round.status === "coming_soon" ? "info" : "error"
			);
		}
	};

	return (
		<div>
			<Header />
			<div className="home-container">
				{!showResultCard ? (
					<>
						<div className="player-info">
							<div className="info-item name-item">
								<div className="info-title">Welcome:</div>
								<div className="info-content">
									{userData ? userData.name : "Loading..."}
								</div>
							</div>
							<div className="vertical-line"></div>
							<div className="info-item referral-code-item">
								<div className="info-title">Referral Code:</div>
								<div className="info-content">
									{userData
										? userData.referral_id
										: "Loading..."}
								</div>
							</div>
							<div className="vertical-line"></div>
							<div className="info-item points-earned-item">
								<div className="info-title">Total Points:</div>
								<div className="info-content">
									{userData
										? userData.total_points
										: "Loading..."}
								</div>
							</div>
						</div>

						<div className="mobile-player-info">
							<div className="mobile-name-item">
								<div className="info-title">Welcome:</div>
								<div className="info-content">
									{userData ? userData.name : "Loading..."}
								</div>
							</div>
							<div className="horizontal-line"></div>
							<div className="referral-points-container">
								<div className="info-item">
									<div className="info-title">
										Referral Code:
									</div>
									<div className="info-content">
										{userData
											? userData.referral_id
											: "Loading..."}
									</div>
								</div>
								<div className="vertical-line"></div>
								<div className="info-item">
									<div className="info-title">
										Total Points:
									</div>
									<div className="info-content">
										{userData
											? userData.total_points
											: "Loading..."}
									</div>
								</div>
							</div>
						</div>

						<div className="game-status-box">
							<p className="quiz-selection-text">
								Select Your Quiz
							</p>
						</div>

						<div className="round-buttons-container">
							{rounds.length > 0 ? (
								rounds.map((round, index) => (
									<HexagonButton
										key={index}
										label="Round"
										roundText={round.round_name}
										roundNumber={round.round_id}
										instructionText="Instructions"
										onClick={() => handleButtonClick(round)}
										// disabled={round.action !== "startGame"}
									/>
								))
							) : (
								<div
									style={{
										position: "absolute",
										top: "60%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}>
									<div
										style={{
											borderTop: "7px solid #6a2a44",
											borderRight: "7px solid #6a2a44",
											borderBottom: "7px solid #d1b200",
											borderLeft: "7px solid #6a2a44",
											borderRadius: "50%",
											width: "60px",
											height: "60px",
											animation:
												"spin 2s linear infinite",
										}}
									/>
								</div>
							)}
						</div>
					</>
				) : (
					<ResultCard roundId={selectedRoundId} />
				)}

				{showInstructions && (
					<Instruction
						onClose={() => setShowInstructions(false)}
						setRedirectedFromHome={setRedirectedFromHome}
						roundId={selectedRoundId}
					/>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default HomePage;
