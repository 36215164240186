import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import authAPI from "../../config/authAPI";
import userAPI from "../../config/userAPI";
import kbcLogo from "../../assets/logo/Logo.png";
import tkmLogo from "../../assets/logo/Toloba-Logo.png";
import "./Header.css";
import Leaderboard from "../Leaderboard/leaderBoard";
import { useLocation } from "react-router-dom";

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [leaderboardOpen, setLeaderboardOpen] = useState(false);
	const [referralId, setReferralId] = useState("");
	const location = useLocation();

	// Refs for menu and share referral button
	const menuRef = useRef(null);
	const referralRef = useRef(null);

	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};

	const handleLogout = async () => {
		try {
			await axios.post(authAPI.LOGOUT_URL, null, {
				withCredentials: true,
			});
			window.location.href = "/auth";
		} catch (error) {
			console.error("Error during logout:", error);
		}
	};

	const toggleLeaderboard = () => {
		setLeaderboardOpen((prev) => !prev);
	};

	// Fetch user's referral ID when the header loads
	useEffect(() => {
		const fetchReferralId = async () => {
			try {
				const response = await axios.get(userAPI.REFERRAL_URL, {
					withCredentials: true,
				});
				setReferralId(response.data.referral_id);
			} catch (error) {
				console.error("Error fetching referral ID:", error);
			}
		};
		fetchReferralId();
	}, []);

	const handleShareReferral = () => {
		const referralLink = `${window.location.origin}/auth?referral_id=${referralId}`;
		const shareData = {
			title: "Join this awesome quiz platform!",
			text: "Use my referral link to join Kaun Banega Champion and earn points:",
			url: referralLink,
		};

		if (navigator.share) {
			navigator
				.share(shareData)
				.then(() => {
					console.log("Referral link shared successfully!");
				})
				.catch((err) => {
					console.error("Error sharing the referral link:", err);
				});
		} else {
			navigator.clipboard.writeText(referralLink);
			alert("Referral link copied to clipboard! Share it manually.");
		}
	};

	// Handle clicks outside menu or referral button
	useEffect(() => {
		const handleClickOutside = (event) => {
		  if (
			menuOpen &&
			menuRef.current &&
			!menuRef.current.contains(event.target) &&
			!event.target.closest('.leaderboard-content') // Exclude clicks inside Leaderboard
		  ) {
			setMenuOpen(false);
		  }
		};
	
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
		  document.removeEventListener("mousedown", handleClickOutside);
		};
	  }, [menuOpen]);

	return (
		<div className="border">
			<div className="header" ref={menuRef}>
				<div className="header-left">
					<img src={kbcLogo} alt="Toloba Logo" className="logo" />
					<span className="header-text">KAUN BANEGA CHAMPION</span>
				</div>
				{location.pathname !== "/quiz" &&
				location.pathname !== "/live/fff" &&
				location.pathname !== "/live/kbc" ? (
					<>
						<div className="menu-icon" onClick={toggleMenu}>
							<div className="menu-line"></div>
							<div className="menu-line"></div>
							<div className="menu-line"></div>
						</div>
						<div
							className={`header-right ${
								menuOpen ? "open" : ""
							}`}>
							<button
								className="share-referral"
								onClick={handleShareReferral}
								ref={referralRef} // Add ref for share referral button
							>
								Share your Referral
							</button>
							<button
								onClick={toggleLeaderboard}
								className="share-referral">
								Leaderboard
							</button>
							<button onClick={handleLogout} className="logout">
								Logout
							</button>
						</div>
					</>
				) : (
					<div className="header-right-logo logo">
						<img src={tkmLogo} alt="Toloba Logo" className="logo" />
					</div>
				)}
			</div>
			<Leaderboard isOpen={leaderboardOpen} onClose={toggleLeaderboard} />
		</div>
	);
};

export default Header;
