import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import resultAPI from "../../config/resultAPI";
import Coupon1 from "../../assets/images/Coupon-1.jpg"; // Moved here
import Coupon2 from "../../assets/images/Coupon-2.jpg"; // Moved here
import "./resultQuiz.css";

const ResultCard = ({ roundId }) => {
	const [points, setPoints] = useState(0);
	const navigate = useNavigate();

	const images = [Coupon1, Coupon2]; // Defined here, so it's not passed as props anymore

	useEffect(() => {
		const fetchPoints = async () => {
			try {
				const response = await axios.post(
					resultAPI.POINTS_URL,
					{ round_id: roundId },
					{
						withCredentials: true,
					}
				);
				setPoints(response.data.points);
			} catch (error) {
				console.error("Error fetching points", error);
			}
		};

		fetchPoints();
	}, []);

	const handleGoHome = () => {
		navigate("/home", { replace: true });
		window.location.reload();
	};

	const handleDownload = (src) => {
		const link = document.createElement("a");
		link.href = src;
		link.download = `coupon-${Date.now()}.png`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div className="result-card">
			<div className="result-card-header">
				<h2>Congratulations!</h2>
				<p className="points-coupon">
					Points earned:{" "}
					<span className="points-number">{points}</span>
				</p>
				<button className="home-button" onClick={handleGoHome}>
					Home
				</button>
				<button
					className="download-button"
					onClick={() =>
						images.forEach((image) => handleDownload(image))
					}>
					Download Coupons
				</button>
			</div>

			<div className="result-card-content">
				<div
					className={`image-gallery ${
						images.length === 1
							? "one-image"
							: images.length === 2
							? "two-images"
							: images.length === 3
							? "three-images"
							: "four-to-six-images"
					}`}>
					{images.map((src, index) => (
						<img
							key={index}
							src={src}
							alt={`Result ${index + 1}`}
							className="result-image"
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ResultCard;
