import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";
import { toast } from "react-toastify";
import BaseURL from "../../config/baseURL";
import kbcAPI from "../../config/kbcAPI";
import Header from "../../components/Header/Header";
import showQuestionSound from "../../assets/audio/Next-Question.mp3";
import freezeOptionSound from "../../assets/audio/Lock.mp3";
import "react-toastify/dist/ReactToastify.css";
import "./adminKBC.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

const socket = io.connect(BaseURL.SOCKET_URL);

const AdminKBCPage = () => {
	const [levels, setLevels] = useState([]);
	const [questionsByLevel, setQuestionsByLevel] = useState({});
	const [questions, setQuestions] = useState([]);
	const [selectedLevel, setSelectedLevel] = useState(null);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [freezeEnabled, setFreezeEnabled] = useState(false);
	const [showResultEnabled, setShowResultEnabled] = useState(false);
	const [startTime, setStartTime] = useState(null);
	const [selectedLifeline, setSelectedLifeline] = useState(null);
	const [fiftyFiftyOptions, setFiftyFiftyOptions] = useState([]); // Store 2 selected options
	const [correctOption, setCorrectOption] = useState(null); // Store correct answer
	const [usedQuestions, setUsedQuestions] = useState([]);
	const [fiftyFiftyUsed, setFiftyFiftyUsed] = useState(false);
	const [audiencePollUsed, setAudiencePollUsed] = useState(false);
	const [endSelectedLevel, setEndSelectedLevel] = useState(null);
	// Import or create audio objects
	const showQuestionAudioRef = useRef(null);
	const freezeOptionAudioRef = useRef(null);

	const playAudio = (audioRef) => {
		if (audioRef.current) {
			audioRef.current.currentTime = 0; // Restart audio
			audioRef.current.play().catch((error) => {
				console.error("Error playing audio:", error);
			});
		}
	};

	// Fetch levels and questions on component mount
	useEffect(() => {
		const fetchLevelsAndQuestions = async () => {
			try {
				const levelsResponse = await axios.get(kbcAPI.LEVELS_URL);
				setLevels(levelsResponse.data.data);

				const questionsResponse = await axios.get(kbcAPI.QUESTIONS_URL);
				const groupedQuestions = groupQuestionsByLevel(
					questionsResponse.data.data
				);
				setQuestionsByLevel(groupedQuestions);

				const shownQuesResponse = await axios.get(
					kbcAPI.SHOWN_QUESTIONS_URL
				);
				setUsedQuestions(shownQuesResponse.data.shownQuestions);
			} catch (error) {
				toast.error("Failed to load levels or questions");
			}
		};
		const fetchGameState = async () => {
			try {
				const response = await axios.get(kbcAPI.GAME_STATE_URL);

				if (response.data.data.lifeline) {
					if (response.data.data.lifeline === "audience_poll") {
						setAudiencePollUsed(true);
					}
					if (response.data.data.lifeline === "50-50") {
						setFiftyFiftyUsed(true);
					}
					if (response.data.data.lifeline === "both") {
						setAudiencePollUsed(true);
						setFiftyFiftyUsed(true);
					}
				}
			} catch (error) {
				console.error("Failed to fetch lifeline:", error);
			}
		};

		fetchLevelsAndQuestions();
		fetchGameState();
	}, []);

	const fetchShownQuestions = async () => {
		try {
			const response = await axios.get(kbcAPI.SHOWN_QUESTIONS_URL);
			setUsedQuestions(response.data.shownQuestions);
		} catch (error) {
			toast.error("Failed to fetch shown questions");
		}
	};

	// Group questions by level
	const groupQuestionsByLevel = (questions) => {
		return questions.reduce((acc, question) => {
			const levelId = question.level_id;
			if (!acc[levelId]) {
				acc[levelId] = [];
			}
			acc[levelId].push(question);
			return acc;
		}, {});
	};

	const handleQuestionClick = async (question) => {
		socket.emit("kbc-show_host_question", question);
		toast.success(`Question ${question.q_id} shown on host screen!`);
		// Mark question as shown
		setSelectedQuestion(question);
		setCorrectOption(question.correct_option);
	};

	const showQuestionToPlayer = () => {
		if (selectedQuestion) {
			playAudio(showQuestionAudioRef);
			socket.emit("kbc-show_player_question", selectedQuestion); // Now show to player.
			toast.success("Question displayed to players!");
		}
	};

	const showOptions = () => {
		socket.emit("kbc-show_options", selectedQuestion);
		toast.success("Options displayed to players!");
		setFreezeEnabled(true);
		setStartTime(new Date());
	};

	const handleOptionSelect = (option) => {
		setSelectedOption(option); // Track selected option
	};

	const handleFreezeOption = async () => {
		if (!selectedOption) {
			toast.error("Please select an option before freezing.");
			return;
		}

		playAudio(freezeOptionAudioRef);
		// Emit freeze event to stop timer and update UI
		await socket.emit(
			"kbc-freeze_option",
			selectedOption,
			selectedQuestion
		);

		// Enable the "Show Result" button
		setShowResultEnabled(true);
	};

	const showResult = () => {
		socket.emit("kbc-show_result");
		toast.success("Results displayed!");
		setSelectedQuestion(null);
		setSelectedOption(null);
		setFreezeEnabled(false);
		setShowResultEnabled(false);
		fetchShownQuestions();
	};

	const stopTimer = () => {
		socket.emit("stop_timer");
		toast.success("Timer stopped!");
	};

	// Handle lifeline selection
	const selectLifeline = (lifeline) => {
		setSelectedLifeline((prev) => (prev === lifeline ? null : lifeline));
	};

	// Handle 50-50 lifeline: Select 2 options to show
	const handleFiftyFiftyOptionSelect = (option) => {
		if (option === correctOption) {
			toast.error("Correct option is already selected!");
			return;
		}

		// If only one incorrect option is selected, store it
		setFiftyFiftyOptions([correctOption, option]);
	};

	// Use lifeline on button click
	const useLifeline = () => {
		if (selectedLifeline === "50-50") {
			if (fiftyFiftyOptions.length === 2) {
				socket.emit("kbc-use_lifeline", {
					lifeline: "50-50",
					options: fiftyFiftyOptions,
				});

				toast.success("50-50 lifeline used!");
				setFiftyFiftyUsed(true);
			} else {
				toast.error("Please select one incorrect option!");
			}
		}
		if (selectedLifeline === "audience_poll") {
			// Disable the audience poll button on the user screen
			socket.emit("kbc-use_lifeline", { lifeline: "audience_poll" });
			toast.success("Audience poll lifeline used!");
			setAudiencePollUsed(true);
		}
		setSelectedLifeline(null);
	};

	const handleLevelChange = (event) => {
		setEndSelectedLevel(event.target.value);
	};

	const endGame = async () => {
		try {
			const response = await axios.post(
				kbcAPI.END_GAME_URL,
				{ endSelectedLevel },
				{
					withCredentials: true,
				}
			);

			const { name, points, prize } = response.data;
			socket.emit("end_game", { name, points, prize });

			toast.success("Game ended! Result shown.");
		} catch (error) {
			toast.error("Error ending the game.");
		}
	};

	return (
		<>
			<Header />
			<div className="admin-kbc-container">
				<div className="levels-column">
					{levels.map((level) => (
						<div key={level.level} className="level-row">
							<div className="level-text">
								Level {level.level}
							</div>
							<div className="questions-row">
								{questionsByLevel[level.level]?.map((q) => (
									<button
										key={q.q_id}
										className={`question-box ${
											selectedQuestion?.q_id === q.q_id
												? "selected"
												: ""
										}`}
										onClick={() => handleQuestionClick(q)}
										disabled={usedQuestions.includes(
											q.q_id
										)}
										title={q.question_text}>
										Q.{q.q_id}
									</button>
								))}
							</div>
						</div>
					))}
				</div>

				<div className="controls-column">
					<h2 className="controls-heading">Controls</h2>
					<div className="a-button-container">
						<audio
							ref={showQuestionAudioRef}
							src={showQuestionSound}
						/>
						<audio
							ref={freezeOptionAudioRef}
							src={freezeOptionSound}
						/>

						<button
							onClick={showQuestionToPlayer}
							disabled={!selectedQuestion}
							className="control-button">
							Show Question
						</button>
						<button
							onClick={showOptions}
							disabled={!selectedQuestion}
							className="control-button">
							Show Options
						</button>
						<div
							disabled={!selectedQuestion}
							className="freeze-box">
							<div>
								{["A", "B", "C", "D"].map((option) => (
									<button
										disabled={!selectedQuestion}
										onClick={() =>
											handleOptionSelect(option)
										}
										className={`${
											selectedOption === option
												? "selected"
												: "question-box"
										}`}>
										{option}
									</button>
								))}
							</div>
							<button
								onClick={handleFreezeOption}
								disabled={!freezeEnabled}
								className="control-button">
								Freeze Option
							</button>
						</div>
						<button
							onClick={showResult}
							disabled={!showResultEnabled}
							className="control-button">
							Show Result
						</button>
						<button
							onClick={stopTimer}
							disabled={!selectedQuestion}
							className="control-button">
							Stop Timer
						</button>
						<div className="freeze-box">
							<div>
								<button
									onClick={() =>
										selectLifeline("audience_poll")
									}
									disabled={
										!selectedQuestion || audiencePollUsed
									}
									className={`question-box ${
										selectedLifeline === "audience_poll"
											? "selected"
											: ""
									}`}>
									<FontAwesomeIcon
										icon={faUserFriends}
										// style={{ marginRight: "5px", color: "gold" }}
									/>
								</button>
								<button
									onClick={() => selectLifeline("50-50")}
									disabled={
										!selectedQuestion || fiftyFiftyUsed
									}
									className={`question-box ${
										selectedLifeline === "50-50"
											? "selected"
											: ""
									}`}>
									50-50
								</button>
							</div>
							{selectedLifeline === "50-50" && (
								<div className="fifty-fifty-options">
									{["A", "B", "C", "D"].map((option) => (
										<button
											key={option}
											onClick={() =>
												handleFiftyFiftyOptionSelect(
													option
												)
											}
											className={
												fiftyFiftyOptions.includes(
													option
												)
													? "selected"
													: "question-box"
											}>
											{option}
										</button>
									))}
								</div>
							)}
							<button
								onClick={useLifeline}
								disabled={!selectedQuestion}
								className="control-button">
								Use lifeline
							</button>
						</div>
						<div className="freeze-box">
							<select onChange={handleLevelChange}>
								<option value="">Select level</option>
								{levels.map((level) => (
									<option
										key={level.level}
										value={level.level}>
										Level {level.level}
									</option>
								))}
							</select>
							<button
								onClick={endGame}
								// disabled={!selectedQuestion}
								className="control-button">
								End Game
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminKBCPage;
