import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import fffAPI from "../../config/fffAPI";
import kbcAPI from "../../config/kbcAPI";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Presents from "../../assets/logo/Presents.png";
import TKM from "../../assets/logo/Toloba-Logo.png";
import Logo from "../../assets/logo/Logo.png";
import "./liveLoginPage.css";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // FontAwesome for spinner

const LoginFFF = () => {
	const [itsId, setItsId] = useState("");
	const [isLoading, setIsLoading] = useState(false); // State for loading

	const navigate = useNavigate();

	const showToast = (message, severity) => {
		toast(message, {
			type: severity,
			position: "top-right",
			autoClose: 3000,
			style: {
				backgroundColor: "#6a2a44",
				color: "#fff",
				top: 80,
				marginTop: 2,
			},
		});
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		setIsLoading(true); // Start spinner
		try {
			const loginResponse = await axios.post(
				fffAPI.LOGIN_URL,
				{ its_id: itsId },
				{ withCredentials: true }
			);

			if (loginResponse.data.message === "Login Successful") {
				await axios.post(kbcAPI.SET_KBC_ITS_ID_URL, { its_id: itsId });
			}

			window.location.href = "/live/fff";
		} catch (error) {
			if (error.response && error.response.data.message) {
				showToast(error.response.data.message, "error");
			} else {
				showToast(
					"An error occurred. Please try again later.",
					"error"
				);
			}
		} finally {
			setIsLoading(false); // Stop spinner
		}
	};

	return (
		<>
			<Header />
			<div className="fff-login-card">
				<h1 className="fff-login-title">
					Welcome to Fastest Finger First
				</h1>
				<form className="fff-login-form" onSubmit={handleLogin}>
					<h2 className="fff-login-head">Login here</h2>
					<input
						type="text"
						placeholder="Enter your ITS ID"
						onChange={(e) => setItsId(e.target.value)}
						className="fff-login-input"
						value={itsId}
						required
					/>
					<button
						type="submit"
						className="fff-login-btn"
						disabled={isLoading || !itsId}>
						{isLoading ? (
							<i className="fas fa-spinner fa-spin"></i>
						) : (
							"Login"
						)}
					</button>
				</form>
				<div className="fff-login-images">
					<img src={TKM} alt="TKM" className="fff-login-image" />
					<img
						src={Presents}
						alt="Presents"
						className="fff-login-image"
					/>
					<img src={Logo} alt="KBC" className="fff-login-image" />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default LoginFFF;
