import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./instruction.css";

const Instruction = ({ onClose, setRedirectedFromHome, roundId }) => {
	const [timer, setTimer] = useState(10); // Initialize a timer state with 10 seconds
	const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Track button enabled status
	const instructions = [
		"This is instruction one. Follow this carefully.",
		"Instruction two goes here. Make sure you understand.",
		"Here’s instruction three. It's important to know.",
		"This is the fourth instruction. Don't skip this step.",
		"Finally, instruction five is essential for success.",
	];
	const cardRef = useRef(null);
	const navigate = useNavigate();

	const handleAgreed = () => {
		setRedirectedFromHome(true);
		navigate("/quiz", { state: { roundId }, replace: true });
		const elem = document.documentElement;
		if (elem.requestFullscreen) elem.requestFullscreen();
		else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
		else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
		else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
	};

	// Timer effect to count down from 10 seconds
	useEffect(() => {
		if (timer > 0) {
			const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
			return () => clearInterval(countdown);
		} else {
			setIsButtonEnabled(true); // Enable button after timer ends
		}
	}, [timer]);

	// Detect clicks outside the instruction card
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (cardRef.current && !cardRef.current.contains(event.target)) {
				onClose();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	return (
		<div className="overlay">
			<div className="instruction-card" ref={cardRef}>
				<button className="close-button" onClick={onClose}>✖</button>
				<h2 className="heading-instruction">Instructions</h2>
				<ul>
					{instructions.map((instruction, index) => (
						<li key={index}>{instruction}</li>
					))}
				</ul>
				<button 
					className="agree-button" 
					onClick={handleAgreed} 
					disabled={!isButtonEnabled}
				>
					{isButtonEnabled ? "I Agree" : `You can agree in ${timer}s`}
				</button>
			</div>
		</div>
	);
};

export default Instruction;
