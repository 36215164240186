import React from "react";
import styled from "styled-components";

// Hexagon container with longer width
const HexagonContainer = styled.div`
	position: relative;
	width: 700px; /* Static width */
	height: 80px; /* Static height */
	margin: 10px auto;
`;

// Gradient border for hexagon
const GradientBorder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	clip-path: polygon(
		35px 0%,
		calc(100% - 35px) 0%,
		100% 50%,
		calc(100% - 35px) 100%,
		35px 100%,
		0% 50%
	);
	background: linear-gradient(
		to bottom,
		#c29445,
		#dfcd77,
		#c29445
	); /* Gradient gold */
	z-index: 0;
`;

// Hexagon shape
const HexagonShape = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	clip-path: polygon(
		35px 0%,
		calc(100% - 35px) 0%,
		100% 50%,
		calc(100% - 35px) 100%,
		35px 100%,
		0% 50%
	);
	background-color: ${(props) => props.bgColor || "#59072b"};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
	color: ${(props) => props.textColor || "white"};
	font-size: 18px;
	font-weight: bold;
	margin-left: 10px;
	margin-right: 10px;
`;

// Left side for name
const Name = styled.div`
	margin-left: 20px;
	text-align: left;
	font-size: 1.6rem;
`;

// Right side for time
const Time = styled.div`
	margin-right: 20px;
	text-align: right;
	font-size: 1.4rem;
`;

// Hexagon component with name and time
const HexagonResult = ({ name, time, isCorrect, isWinner }) => {
	const winnerBgColor = "#71bd6c"; // Winner's background color (same as the border color)
	const defaultBgColor = "#59072b"; // Default background color
	const winnerTextColor = "#59072b"; // Text color for winner (same as the default background)
	const regularTextColor = isCorrect ? "#71bd6c" : "#db0f1b"; // Green for correct, red for incorrect

	// Determine background and text colors based on winner status and correctness
	const bgColor = isWinner ? winnerBgColor : defaultBgColor;
	const textColor = isWinner ? winnerTextColor : regularTextColor;

	return (
		<HexagonContainer>
			<GradientBorder />
			<HexagonShape bgColor={bgColor} textColor={textColor}>
				<Name>{name}</Name>
				<Time>{/^20\.\d+$/.test(time) ? "—" : `${time} seconds`}</Time>
			</HexagonShape>
		</HexagonContainer>
	);
};

export default HexagonResult;
