import BaseURL from "./baseURL";

const fffAPI = {
	LOGIN_URL: `${BaseURL.LOCAL_BASE_URL}/fff/login`,
	QUESTIONS_URL: `${BaseURL.LOCAL_BASE_URL}/fff/questions`,
	SUBMIT_ANSWER_URL: `${BaseURL.LOCAL_BASE_URL}/fff/submit-answer`,
	RESULTS_URL: `${BaseURL.LOCAL_BASE_URL}/fff/results`,
	SHOWN_QUESTIONS_URL: `${BaseURL.LOCAL_BASE_URL}/fff/shown-questions`,
};
export default fffAPI;
