import React, { useState, useEffect } from "react";
import axios from "axios";
import fffAPI from "../../config/fffAPI";
import HexagonResult from "../Hexagons/hexagonResult";
import "./resultFFF.css";

const ResultCard = ({ question, answer, result }) => {
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState([]);
	const [showAnswers, setShowAnswers] = useState(false);
	const [showResults, setShowResults] = useState(false);

	useEffect(() => {
		const fetchResults = async () => {
			try {
				const response = await axios.get(fffAPI.RESULTS_URL, {
					withCredentials: true,
				});
				setResults(response.data.results);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch results:", error);
				setLoading(false);
			}
		};

		fetchResults();
	}, []);

	const formatTime = (time) => {
		const parts = time.split(":");
		return parts[2];
	};

	return (
		<div className="fff-result-card">
			<h2 className="heading-result">
				{answer ? "Correct Answer" : "Results"}
			</h2>

			{loading ? (
				<div className="fff-waiting-message">
					<p>Waiting for response...</p>
					<div
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}>
						<div
							style={{
								borderTop: "7px solid #6a2a44",
								borderRight: "7px solid #6a2a44",
								borderBottom: "7px solid #d1b200",
								borderLeft: "7px solid #6a2a44",
								borderRadius: "50%",
								width: "70px",
								height: "70px",
								animation: "spin 2s linear infinite",
							}}
						/>
					</div>
				</div>
			) : (
				<div className="fff-result-container">
					{answer && (
						<div className="answers-container">
							<div className="question-container">
								<p className="question-fff">
									{question.question_text}
								</p>
							</div>
							<div className="options-fff">
								{question.correct_sequence
									.split("")
									.map((option, index) => (
										<div key={index} className="option-div">
											<p>
												<span className="option-letter">
													{option}:
												</span>{" "}
												{
													question[
														`option_${option.toLowerCase()}`
													]
												}
											</p>
										</div>
									))}
								{/* <div className="option-div">
									<p>
										<span className="option-letter">
											B.
										</span>{" "}
										Option 1
									</p>
								</div>
								<div className="option-div">
									<p>
										<span className="option-letter">
											D.
										</span>{" "}
										Option 2
									</p>
								</div>
								<div className="option-div">
									<p>
										<span className="option-letter">
											A.
										</span>{" "}
										Option 3
									</p>
								</div>
								<div className="option-div">
									<p>
										<span className="option-letter">
											C.
										</span>{" "}
										Option 4
									</p>
								</div> */}
							</div>
						</div>
					)}
					{result && (
						<div className="results-container">
							{results.map((result, index) => (
								<HexagonResult
									key={index}
									name={result.user}
									time={formatTime(result.time)}
									isCorrect={result.isCorrect}
									isWinner={result.isWinner}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ResultCard;
