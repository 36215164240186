import React from "react";
import "./resultKBC.css"; // Import the CSS file

const ResultKBC = ({ name, points, prize }) => {
	return (
		<div className="result-kbc-card">
			<div className="result-kbc-header">
				<h2>
					Congratulations,{" "}
					<span className="points-number">{name}</span>!
				</h2>
				<p className="points-kbc">
					You scored:{" "}
					<span className="points-number">{points} points</span>
				</p>
				{prize && (
					<p className="reward-kbc">
						You won: <span className="points-number">{prize}</span>
					</p>
				)}
			</div>
		</div>
	);
};

export default ResultKBC;
