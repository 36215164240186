import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";
import BaseURL from "../../config/baseURL";
import kbcAPI from "../../config/kbcAPI";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HexagonKBC from "../../components/Hexagons/hexagonFFF"; // Reuse hexagon component from FFFPage
import ResultKBC from "../../components/Result Cards/resultKBC";
import Logo from "../../assets/logo/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import "./kbcPage.css";

const socket = io.connect(BaseURL.SOCKET_URL);

const KBCPage = () => {
	const [question, setQuestion] = useState([]);
	const [optionsVisible, setOptionsVisible] = useState(false);
	const [results, setResults] = useState(null);
	const [levels, setLevels] = useState([]);
	const [timeLeft, setTimeLeft] = useState(0);
	const [percentage, setPercentage] = useState(100);
	const [isHost, setIsHost] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [correctAnswer, setCorrectAnswer] = useState(null);
	const [audiencePollSelected, setAudiencePollSelected] = useState(false);
	const [fiftyFiftySelected, setFiftyFiftySelected] = useState(false);
	const [gameEnded, setGameEnded] = useState(false);
	const [endResult, setEndResult] = useState(null);
	const [currentLevel, setCurrentLevel] = useState(0);
	const remainingTime = useRef(null);
	const isHostRef = useRef(false);
	const isTimerRunning = useRef(false);
	const requestSent = useRef(false);

	useEffect(() => {
		//update remaining time by 1 second each when timer is running
		if (isTimerRunning.current) {
			remainingTime.current = timeLeft;
		}
	}, [timeLeft]);

	useEffect(() => {
		const fetchLevels = async () => {
			try {
				const response = await axios.get(kbcAPI.LEVELS_URL);
				setLevels(response.data.data);
			} catch (error) {
				console.error("Failed to load levels:", error);
			}
		};
		const checkHostStatus = async () => {
			try {
				const response = await axios.get(kbcAPI.HOST_STATUS_URL, {
					withCredentials: true,
				});
				isHostRef.current = response.data;
				setIsHost(response.data);
			} catch (error) {
				console.error("Failed to check host status:", error);
				setIsHost(false);
			}
		};
		const fetchGameState = async () => {
			try {
				const response = await axios.get(kbcAPI.GAME_STATE_URL);

				if (response.data.data.lifeline) {
					if (response.data.data.lifeline === "audience_poll") {
						setAudiencePollSelected(true);
					}
					if (response.data.data.lifeline === "50-50") {
						setFiftyFiftySelected(true);
					}
					if (response.data.data.lifeline === "both") {
						setAudiencePollSelected(true);
						setFiftyFiftySelected(true);
					}
				}
				setCurrentLevel(response.data.data.currentLevel);
			} catch (error) {
				console.error("Failed to fetch lifeline:", error);
			}
		};

		checkHostStatus();
		fetchLevels();
		fetchGameState();
	}, []);

	useEffect(() => {
		socket.on("kbc-show_host_question", (questionData) => {
			if (isHostRef.current) {
				setQuestion(questionData); // Show question to the host only.
				setOptionsVisible(true);
				setTimeLeft(questionData.timer);
				setCorrectAnswer(questionData.correct_option);
				setSelectedOption(null);
				setResults(null);
			}
		});

		socket.on("kbc-show_player_question", (questionDataPlayer) => {
			// if (!isHostRef.current) {}
			setTimeLeft(questionDataPlayer.timer);
			setQuestion(questionDataPlayer);
			setOptionsVisible(false);
			setSelectedOption(null);
			setCorrectAnswer(null);
			setResults(null);
		});

		socket.on("kbc-show_options", () => {
			setTimeLeft(question.timer);
			setCorrectAnswer(question.correct_option);
			setOptionsVisible(true);
			isTimerRunning.current = true;
			timeRunner();
		});

		socket.on("kbc-freeze_option", (answer, question) => {
			isTimerRunning.current = false;
			// Update the background color of the selected answer on all screens
			setSelectedOption(answer);

			if (!requestSent.current && !isHostRef.current) {
				requestSent.current = true;
				// Submit the selectep option
				const data = {
					question_id: question.q_id,
					level_id: question.level_id,
					answer_given: answer,
					time_taken: question.timer - remainingTime.current,
				};
				// Submit answer to backend

				if (data) {
					try {
						console.log("before axios");

						axios.post(kbcAPI.SUBMIT_ANSWER_URL, data, {
							withCredentials: true,
						});

						console.log("after axios");
					} catch (error) {
						console.error("Failed to submit answer:", error);
					}
				}
			}
		});

		socket.on("kbc-show_result", () => {
			setResults(true);
			if (selectedOption === question.correct_option) {
				setCorrectAnswer(selectedOption);
				setCurrentLevel(currentLevel + 1);
			} else {
				setCorrectAnswer(question.correct_option); // Keep level same if wrong
			}
			requestSent.current = false;
		});

		socket.on("stop_timer", () => {
			isTimerRunning.current = false;
		});

		socket.on("kbc-use_lifeline", (data) => {
			if (data) {
				//if theres already a lifeline used then send both else send the lifeline
				if (data.lifeline === "audience_poll" && fiftyFiftySelected) {
					const lifeline = "both";
					try {
						axios.post(kbcAPI.UPDATE_LIFELINE_URL, lifeline, {
							withCredentials: true,
						});
					} catch (error) {
						console.error("Failed to use lifeline:", error);
					}
				} else if (data.lifeline === "50-50" && audiencePollSelected) {
					const lifeline = "both";
					try {
						axios.post(kbcAPI.UPDATE_LIFELINE_URL, lifeline, {
							withCredentials: true,
						});
					} catch (error) {
						console.error("Failed to use lifeline:", error);
					}
				} else {
					try {
						axios.post(kbcAPI.UPDATE_LIFELINE_URL, data.lifeline, {
							withCredentials: true,
						});
					} catch (error) {
						console.error("Failed to use lifeline:", error);
					}
				}
			}

			if (data.lifeline === "audience_poll") {
				setAudiencePollSelected(true);
			}

			if (data.lifeline === "50-50") {
				const { options: selectedOptions } = data;

				const filteredOptions = {
					option_a: selectedOptions.includes("A")
						? question.option_a
						: null,
					option_b: selectedOptions.includes("B")
						? question.option_b
						: null,
					option_c: selectedOptions.includes("C")
						? question.option_c
						: null,
					option_d: selectedOptions.includes("D")
						? question.option_d
						: null,
				};

				setQuestion((prevQuestion) => ({
					...prevQuestion,
					...filteredOptions,
				}));

				setFiftyFiftySelected(true);
			}

			isTimerRunning.current = true;
			timeRunner(remainingTime.current);
		});

		socket.on("end_game", (data) => {
			setEndResult(data);
			setGameEnded(true);
		});
	}, [question, selectedOption]);

	// Fetch levels info on component mount

	const timeRunner = (time = question.timer) => {
		let startTime = null; // Track the start time
		const duration = time; // Use the passed remaining time or the full duration

		const updateTimer = (timestamp) => {
			if (!startTime) startTime = timestamp; // Set start time on the first call
			const elapsed = (timestamp - startTime) / 1000; // Calculate elapsed time
			const remaining = duration - elapsed; // Calculate remaining time

			if (!isTimerRunning.current) {
				return; // Stop the timer if it's paused
			}

			if (remaining <= 0) {
				setTimeLeft(0); // Time is up
				setPercentage(0); // Update the percentage to 0
			} else {
				setTimeLeft(Math.ceil(remaining)); // Update the displayed time
				setPercentage((remaining / question.timer) * 100); // Update the progress bar
				requestAnimationFrame(updateTimer); // Continue the timer
			}
		};

		requestAnimationFrame(updateTimer); // Start the animation
	};

	return (
		<div>
			<Header />
			<div className="kbc-container">
				{gameEnded ? (
					<ResultKBC
						name={endResult.name}
						points={endResult.points}
						prize={endResult.prize}
					/>
				) : (
					<>
						<div className="main-game">
							<div className="central-img">
								<img
									src={Logo}
									alt="Central Game"
									className="game-image"
								/>
							</div>

							{question.timer && (
								<div className="timer-container">
									<div className="timer-svg-container">
										<svg
											viewBox="0 0 200 110"
											className="semicircle-timer">
											<path
												d="M190 100 A90 85 0 1 0 10 100"
												fill="none"
												stroke="#6a2a44" // Background color
												strokeWidth="8"
											/>
											<path
												d="M190 100 A90 85 0 1 0 10 100"
												fill="none"
												stroke="#d4af37" // Golden color
												strokeWidth="8"
												strokeLinecap="round"
												strokeDasharray="275" // Approximate path length
												strokeDashoffset={
													275 * (1 - percentage / 100)
												}
											/>
										</svg>
										<div className="time-text">
											{timeLeft || 0}
										</div>
									</div>
								</div>
							)}

							<div className="question-container">
								<HexagonKBC
									QnA={
										question.question_text
											? question.question_text
											: " "
									}
									disabled={true}
									isQuestion={true}
									className="fade-in"
								/>
							</div>

							<div className="answers-container">
								{["A", "B", "C", "D"].map((letter, index) => {
									let bgColor = "#59072b";
									let textColor = "#fff";

									if (isHost && letter === correctAnswer) {
										textColor = "#71bd6c";
									}

									if (selectedOption === letter) {
										bgColor = "#e0ce78";
										textColor = "#59072b";
									}

									if (results) {
										if (letter === correctAnswer) {
											bgColor = "#71bd6c";
											textColor = "#59072b";
										}
										if (
											letter === selectedOption &&
											selectedOption !== correctAnswer
										) {
											bgColor = "#ff4d4d";
										}
									}

									return (
										<HexagonKBC
											key={index}
											QnA={
												question && optionsVisible
													? question[
															`option_${letter.toLowerCase()}`
													  ]
													: " "
											}
											letter={letter} // Passing the letter A, B, C, D
											disabled={true}
											className="fade-in"
											backgroundColor={bgColor} // Apply the background color dynamically
											textColor={textColor} // Apply the text color dynamically
										/>
									);
								})}
							</div>
						</div>
						<div className="info-box">
							<div className="life-line">
								<div
									className={`fifty-fifty ${
										fiftyFiftySelected
											? "lifeline-used"
											: ""
									}`}>
									50-50
								</div>
								<div
									className={`audience-poll ${
										audiencePollSelected
											? "lifeline-used"
											: ""
									}`}>
									<FontAwesomeIcon
										icon={faUserFriends}
										style={{
											marginRight: "5px",
											color: "gold",
										}}
									/>
								</div>
							</div>
							<div className="questions-list">
								{levels
									.slice()
									.reverse()
									.map((level) => {
										const questionIndex = level.level;
										const isCheckpoint =
											questionIndex % 5 === 0 &&
											questionIndex !== 16; // Checkpoints at 5, 10, 15
										const isLastQuestion =
											questionIndex === 16; // Last question
										const isCurrentQuestion =
											questionIndex === currentLevel;

										return (
											<div
												className={`question-line ${
													isCheckpoint
														? "checkpoint"
														: ""
												} ${
													isLastQuestion
														? "last-question"
														: ""
												} ${
													isCurrentQuestion
														? "current-question"
														: ""
												}`}
												key={questionIndex}>
												<span className="level-number">
													{level.level}
												</span>
												<span className="level-points">
													{level.points} Points
												</span>
												<span className="level-prize">
													{level.prize}
												</span>
											</div>
										);
									})}
							</div>
						</div>
					</>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default KBCPage;
