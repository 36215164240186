import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const HexagonContainer = styled.div`
	position: relative;
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	margin-bottom: 10px;
	cursor: ${(props) => (props.disabled ? "default" : "pointer")};

	@media (max-width: 768px) {
		min-height: ${(props) => (props.isQuestion ? "auto" : "60px")};
	}
`;

const HexagonShape = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	clip-path: polygon(
		35px 0%,
		calc(100% - 35px) 0%,
		100% 50%,
		calc(100% - 35px) 100%,
		35px 100%,
		0% 50%
	);
	/* Use the passed background color prop or a default value */
	background-color: ${(props) => props.backgroundColor || "#59072b"};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${(props) => (props.isQuestion ? "center" : "flex-start")};
	padding: ${(props) => (props.isQuestion ? "20px 40px" : "15px 25px")};
	box-sizing: border-box;
	color: white;
	font-size: ${(props) => (props.isQuestion ? "20px" : "18px")};
	font-weight: normal;
	text-align: ${(props) => (props.isQuestion ? "center" : "left")};
	overflow: hidden;
	word-wrap: break-word;

	@media (max-width: 768px) {
		font-size: ${(props) => (props.isQuestion ? "16px" : "14px")};
		padding: ${(props) => (props.isQuestion ? "15px 30px" : "10px 20px")};
	}
`;

const GradientBorder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	clip-path: polygon(
		35px 0%,
		calc(100% - 35px) 0%,
		100% 50%,
		calc(100% - 35px) 100%,
		35px 100%,
		0% 50%
	);
	background: linear-gradient(to bottom, #c29445, #dfcd77, #c29445);
	z-index: 0;

	@media (max-width: 768px) {
		position: absolute;
	}
`;

const OptionText = styled.div`
	display: flex;
	margin-bottom: 5px;
	align-items: center;
`;

const OptionLetter = styled.span`
	color: ${(props) => (props.selected ? "#59072b" : "gold")};
	font-weight: ${(props) => (props.selected ? "bold" : "")};
	margin-right: 10px;
	margin-left: 10px;
`;

const OptionAnswer = styled.span`
	/* Use the passed textColor prop or a default value */
	color: ${(props) =>
		props.textColor || (props.selected ? "#59072b" : "white")};
	font-weight: ${(props) => (props.selected ? "600" : "")};
`;

const HexagonFFF = ({
	QnA,
	options = [],
	onClick,
	isQuestion = false,
	disabled = false,
	selected = false,
	letter,
	currentQuestionIndex,
	/* Add new props for dynamic styling */
	backgroundColor,
	textColor,
}) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const textRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			if (QnA && textRef.current) {
				const content = textRef.current;

				if (isQuestion) {
					const tempDiv = document.createElement("div");
					tempDiv.style.position = "absolute";
					tempDiv.style.visibility = "hidden";
					tempDiv.style.width = "auto";
					tempDiv.style.height = "auto";
					tempDiv.style.maxWidth =
						window.innerWidth <= 768 ? "100%" : "1300px";
					tempDiv.style.padding = "20px 40px";
					tempDiv.innerHTML = content.innerHTML;
					document.body.appendChild(tempDiv);

					const contentWidth = tempDiv.offsetWidth;
					const contentHeight = tempDiv.offsetHeight;

					document.body.removeChild(tempDiv);

					const baseSize = 400;
					const sizeIncrement = 10;
					const maxSizeIncrease = 500;

					let newWidth = Math.min(
						Math.max(
							baseSize +
								Math.floor(QnA.length / 20) * sizeIncrement,
							contentWidth + 80
						),
						baseSize + maxSizeIncrease
					);

					let newHeight = Math.max(contentHeight + 40, 80);

					const minAspectRatio = 2.5;
					if (newWidth / newHeight < minAspectRatio) {
						newWidth = newHeight * minAspectRatio;
					}

					if (
						dimensions.width !== newWidth ||
						dimensions.height !== newHeight
					) {
						setDimensions({ width: newWidth, height: newHeight });
					}
				} else {
					const baseSize = 450;
					const sizeIncrement = 5;
					const maxSizeIncrease = 100;

					const newWidth = Math.min(
						Math.max(
							baseSize +
								Math.floor(QnA.length / 20) * sizeIncrement,
							window.innerWidth <= 768
								? window.innerWidth - 40
								: baseSize
						),
						baseSize + maxSizeIncrease
					);
					const newHeight = 60;

					if (
						dimensions.width !== newWidth ||
						dimensions.height !== newHeight
					) {
						setDimensions({ width: newWidth, height: newHeight });
					}
				}
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [isQuestion, dimensions, currentQuestionIndex, QnA]);

	const content = isQuestion ? (
		`${QnA}`
	) : (
		<OptionText>
			<OptionLetter selected={selected}>{letter}:</OptionLetter>
			<OptionAnswer selected={selected} textColor={textColor}>
				{QnA}
			</OptionAnswer>
		</OptionText>
	);

	return (
		<HexagonContainer
			onClick={disabled ? null : onClick}
			disabled={disabled}
			width={dimensions.width}
			height={dimensions.height}
			isQuestion={isQuestion}>
			<GradientBorder />
			<HexagonShape
				ref={textRef}
				isQuestion={isQuestion}
				selected={selected}
				backgroundColor={
					backgroundColor
				} /* Pass dynamic background color */
			>
				{content}
			</HexagonShape>
		</HexagonContainer>
	);
};

export default HexagonFFF;
