import BaseURL from "./baseURL";

const kbcAPI = {
	LEVELS_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/levels`,
	QUESTIONS_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/questions`,
	SUBMIT_ANSWER_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/submit`,
	HOST_STATUS_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/host-status`,
	END_GAME_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/end-game`,
	SHOWN_QUESTIONS_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/shown-questions`,
	GAME_STATE_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/game-state`,
	UPDATE_LIFELINE_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/update-lifeline`,
	SET_KBC_ITS_ID_URL: `${BaseURL.LOCAL_BASE_URL}/kbc/set-kbc-its-id`,
};
export default kbcAPI;
