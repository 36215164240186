import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./adminPage.css";

const AdminPage = () => {
	const navigate = useNavigate();

	const routeTo = (path) => {
		navigate(path, { replace: true });
	};

	return (
		<>
			<Header />
			<div className="admin-page-container">
				<div className="admin-card">
					<h1>Admin Control Panel</h1>
					<div className="admin-button-container">
						<button
							className="admin-button fff-button"
							onClick={() => routeTo("/admin/fff")}>
							FFF Admin
						</button>
						<button
							className="admin-button kbc-button"
							onClick={() => routeTo("/admin/kbc")}>
							KBC Admin
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminPage;
