import BaseURL from "./baseURL";

const authAPI = {
	LOGIN_URL: `${BaseURL.LOCAL_BASE_URL}/auth/login`,
	REGISTER_URL: `${BaseURL.LOCAL_BASE_URL}/auth/register`,
	LOGOUT_URL: `${BaseURL.LOCAL_BASE_URL}/auth/logout`,
	AUTH_URL: `${BaseURL.LOCAL_BASE_URL}/auth/check-auth`,
	ADMIN_STATUS_URL: `
    ${BaseURL.LOCAL_BASE_URL}/auth/admin-status`,
};

export default authAPI;
