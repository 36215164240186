import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <span className="circle"></span>Copyright © TOLOBA-UL-KULLIYAAT-IL-MUMENOON UDAIPUR, All rights reserved 2024

      </div>
    </div>
  );
};

export default Footer;
