import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HexagonGame from "../../components/Hexagons/hexagonQuiz";
import { FaArrowRight } from "react-icons/fa";
import quizAPI from "../../config/quizAPI";
import ResultCard from "../../components/Result Cards/resultQuiz";
import "./quizPage.css";
import { useLocation } from "react-router-dom";

const QuizPage = () => {
	const [timeLeft, setTimeLeft] = useState(null);
	const [timerRunning, setTimerRunning] = useState(true);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
	const [showResultCard, setShowResultCard] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [startTime, setStartTime] = useState(null);
	const [isAnswered, setIsAnswered] = useState(false);
	const [questionCompleted, setQuestionCompleted] = useState(false); // Prevent duplicate calls

	const location = useLocation();
	const roundId = location.state.roundId;

	useEffect(() => {
		const handleUnload = async () => {
			await handleAnswerSubmit(); // Auto-save answer on reload or close
		};

		window.addEventListener("beforeunload", handleUnload);

		return () => {
			window.removeEventListener("beforeunload", handleUnload);
		};
	}, [currentQuestionIndex, selectedAnswerIndex, timeLeft]);

	useEffect(() => {
		const fetchQuizData = async () => {
			try {
				// Fetch questions and progress simultaneously
				const [questionsRes, progressRes] = await Promise.all([
					axios.get(`${quizAPI.QUES_URL}/${roundId}`),
					axios.get(quizAPI.PROGRESS_URL, {
						withCredentials: true,
					}),
				]);

				const questionsData = questionsRes.data;
				const { lastAnsweredQuestion } = progressRes.data;

				// Set the questions
				setQuestions(questionsData);

				if (lastAnsweredQuestion) {
					const lastQuestionIndex = questionsData.findIndex(
						(q) =>
							q.question_id === lastAnsweredQuestion.question_id
					);

					// Check if the last answered question is the final question
					if (lastQuestionIndex === questionsData.length - 1) {
						setShowResultCard(true); // If last question answered, show result card
					} else {
						// Otherwise, resume from the next question
						setCurrentQuestionIndex(lastQuestionIndex + 1);
						setTimeLeft(lastAnsweredQuestion.timer);
						setTimerRunning(true);
						setStartTime(new Date());
					}
				} else {
					// Start from the first question
					setTimeLeft(questionsData[0].timer);
					setTimerRunning(true);
					setStartTime(new Date());
				}
			} catch (error) {
				console.error("Error fetching quiz data:", error);
			} finally {
				setLoading(false); // Stop loading when data is fetched
			}
		};

		fetchQuizData();
	}, []);

	useEffect(() => {
		if (timerRunning && timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 1000);
			return () => clearInterval(timer);
		} else if (timeLeft === 0 && !questionCompleted) {
			setQuestionCompleted(true); // Mark as completed to prevent multiple triggers
			setTimerRunning(false);
			handleNextQuestion();
		}
	}, [timerRunning, timeLeft, questionCompleted]);

	const handleAnswerClick = (index, letter) => {
		if (isAnswered) return; // Prevent multiple answers

		setSelectedAnswerIndex((prevIndex) =>
			prevIndex === index ? null : index
		);
	};

	// Utility function to format time in hh:mm:ss
	const formatTimeTaken = (start, end) => {
		const timeDiff = end - start; // Difference in milliseconds

		const hh = String(Math.floor((timeDiff / 3600000) % 24)).padStart(
			2,
			"0"
		);
		const mm = String(Math.floor((timeDiff / 60000) % 60)).padStart(2, "0");
		const ss = String(Math.floor((timeDiff / 1000) % 60)).padStart(2, "0");
		const ms = String(timeDiff % 1000).padStart(3, "0"); // Get milliseconds part

		return `${hh}:${mm}:${ss}.${ms}`;
	};

	const handleNextQuestion = async () => {
		if (questionCompleted) return;
		if (!isAnswered) await handleAnswerSubmit(); // Submit the answer if not done

		if (currentQuestionIndex < questions.length - 1) {
			setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
			setSelectedAnswerIndex(null); // Clear selected answer

			const nextQuestion = questions[currentQuestionIndex + 1];
			if (nextQuestion) {
				setTimeLeft(nextQuestion.timer); // Reset timer for new question
				setTimerRunning(true); // Restart timer
				setStartTime(new Date());
			}
			setIsAnswered(false); // Reset state for the next question
		} else {
			setShowResultCard(true); // End quiz if all questions are answered
		}
		setQuestionCompleted(false); // Reset flag after moving to the next question
	};

	const handleAnswerSubmit = async () => {
		if (isAnswered) return; // Prevent multiple submissions

		setIsAnswered(true); // Mark as answered
		setQuestionCompleted(true); // Set flag to indicate question is completed
		setTimerRunning(false); // Stop the timer

		const answer =
			selectedAnswerIndex === null
				? null
				: String.fromCharCode(65 + selectedAnswerIndex);

		const endTime = new Date();
		const timeTaken = formatTimeTaken(startTime, endTime);

		try {
			await axios.post(
				quizAPI.SUBMIT_ANS_URL,
				{
					question_id: questions[currentQuestionIndex].question_id,
					answer_option: answer,
					time_taken: timeTaken,
				},
				{ withCredentials: true }
			);
			setTimeLeft(null); // Clear timer after submission
		} catch (error) {
			console.error("Error submitting answer:", error);
		}
	};

	// Ensure we get a valid timer value, fallback to 30 seconds if undefined
	const currentTimer = questions[currentQuestionIndex]?.timer || 30;
	const seconds = timeLeft;
	const strokeDasharray = 283;
	const strokeDashoffset =
		timeLeft > 0
			? strokeDasharray - (timeLeft / currentTimer) * strokeDasharray
			: strokeDasharray;

	return (
		<div>
			<Header />
			{loading ? (
				<p>Loading...</p>
			) : !showResultCard ? (
				<div className="game1-container">
					<div className="round-info">
						<div className="quiz-text">
							<span className="info-title">Quiz: Round </span>
							<span className="info-content">1</span>
						</div>
					</div>

					<div className="timer-container">
						<div className="timer-wrapper">
							<svg className="timer-svg" viewBox="0 0 100 100">
								<circle
									className="timer-background"
									cx="50"
									cy="50"
									r="45"
									fill="none"></circle>
								<circle
									className="timer-progress"
									cx="50"
									cy="50"
									r="45"
									fill="none"
									strokeDasharray={strokeDasharray}
									strokeDashoffset={
										strokeDashoffset
									}></circle>
								<text
									x="50%"
									y="40%"
									className="timer-text"
									dominantBaseline="middle"
									textAnchor="middle">
									{seconds < 10 ? `0${seconds}` : seconds}
								</text>
								<text
									x="50%"
									y="65%"
									className="timer-label"
									dominantBaseline="middle"
									textAnchor="middle">
									seconds
								</text>
							</svg>
						</div>
					</div>

					<div className="question-container">
						{questions[currentQuestionIndex] ? (
							<HexagonGame
								QnA={
									questions[currentQuestionIndex]
										?.question_text
								}
								disabled={true}
								isQuestion={true}
								currentQuestionIndex={currentQuestionIndex}
							/>
						) : (
							<p>Loading question...</p>
						)}
					</div>

					<div className="answers-container">
						{questions[currentQuestionIndex] ? (
							["A", "B", "C", "D"].map((letter, index) => (
								<HexagonGame
									key={index}
									QnA={
										questions[currentQuestionIndex][
											`option_${letter.toLowerCase()}`
										]
									}
									onClick={() =>
										handleAnswerClick(index, letter)
									}
									letter={letter}
									selected={selectedAnswerIndex === index}
									currentQuestionIndex={currentQuestionIndex}
								/>
							))
						) : (
							<p>Loading answers...</p>
						)}
					</div>

					<div className="next-question-container">
						<button
							className="next-question-btn"
							onClick={handleNextQuestion}
							disabled={isAnswered}>
							<FaArrowRight className="next-arrow-icon" />
						</button>
					</div>
				</div>
			) : (
				<ResultCard roundId={roundId} />
			)}
			<Footer />
		</div>
	);
};

export default QuizPage;
