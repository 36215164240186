import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import userAPI from "../../config/userAPI";
import "./leaderBoard.css";

const Leaderboard = ({ isOpen, onClose }) => {
	const [players, setPlayers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const leaderboardRef = useRef(null); // Ref to track the leaderboard content

	useEffect(() => {
		if (isOpen) {
			// Fetch Super 30 leaderboard users
			const fetchLeaderboard = async () => {
				try {
					const response = await axios.get(userAPI.LEADERBOARD_URL);
					if (response.data.message) {
						setErrorMessage(response.data.message); // If no users have qualified
					} else {
						setPlayers(response.data); // Set the leaderboard players
					}
				} catch (error) {
					console.error("Error fetching leaderboard:", error);
					setErrorMessage("Failed to load leaderboard. Please try again.");
				} finally {
					setLoading(false);
				}
			};

			fetchLeaderboard();
		}
	}, [isOpen]);

	// Detect clicks outside the leaderboard content
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (leaderboardRef.current && !leaderboardRef.current.contains(event.target)) {
				onClose(); // Close the leaderboard if clicked outside
			}
		};

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		// Cleanup the event listener when the component unmounts or isOpen changes
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, onClose]);

	if (!isOpen) return null;

	return (
		<div className="leaderboard-overlay">
			<div className="leaderboard-content" ref={leaderboardRef}>
				<button className="close-leaderboard" onClick={onClose}>
					×
				</button>
				<h2 className="heading-leaderboard">Leaderboard</h2>
				{loading ? (
					<div
						style={{
							position: "absolute",
							top: "60%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						<div
							style={{
								borderTop: "7px solid #6a2a44",
								borderRight: "7px solid #6a2a44",
								borderBottom: "7px solid #d1b200",
								borderLeft: "7px solid #6a2a44",
								borderRadius: "50%",
								width: "60px",
								height: "60px",
								animation: "spin 2s linear infinite",
							}}
						/>
					</div>
				) : errorMessage ? (
					<p className="error-message">{errorMessage}</p>
				) : (
					<div className="leaderboard-list">
						{players.map((player, index) => (
							<div key={index} className="leaderboard-item">
								<span>{player.name}</span>
								<span style={{ margin: "0 10px" }}>-</span>
								<span>{player.city}</span>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Leaderboard;
