import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import axios from "axios";
import authAPI from "./config/authAPI";
import PrivateRoute from "./config/PrivateRoute";
import LoginPage from "./pages/login_signup/loginPage";
import HomePage from "./pages/homePage/homePage";
import QuizPage from "./pages/quizPage/quizPage";
import AdminPage from "./pages/adminPages/adminPage";
import LoginFFF from "./pages/LiveLoginPage/liveLoginPage";
import FFFPage from "./pages/fffPage/fffPage";
import KBCpage from "./pages/kbcPage/kbcPage";
import AdminFFFPage from "./pages/adminPages/adminFFF";
import AdminKBCPage from "./pages/adminPages/adminKBC";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true); // Add loading state
	const [redirectedFromHome, setRedirectedFromHome] = useState(false);
	const [isAdmin, setIsAdmin] = useState(null);

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const response = await axios.get(authAPI.AUTH_URL, {
					withCredentials: true, // Include cookies in the request.
				});
				setIsAuthenticated(response.data.isAuthenticated);
			} catch (error) {
				setIsAuthenticated(false);
			} finally {
				setLoading(false); // Stop loading when checkAuth completes
			}
		};
		const checkAdminStatus = async () => {
			try {
				const response = await axios.get(authAPI.ADMIN_STATUS_URL, {
					withCredentials: true,
				});
				setIsAdmin(response.data);
			} catch (error) {
				setIsAdmin(false);
			}
		};
		checkAdminStatus();
		checkAuth();
	}, []);

	// Show a loading message while waiting for authentication check
	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Router>
			<ToastContainer />
			<Routes>
				<Route
					exact
					path="/"
					element={<Navigate to="/auth" replace />}
				/>
				<Route
					path="/auth"
					element={
						isAuthenticated ? (
							isAdmin ? (
								<Navigate to="/admin" replace />
							) : (
								<Navigate to="/home" replace />
							)
						) : (
							<LoginPage />
						)
					}
				/>
				<Route
					exact
					path="/home"
					element={
						<PrivateRoute
							element={
								<HomePage
									setRedirectedFromHome={
										setRedirectedFromHome
									}
								/>
							}
						/>
					}
					/* element={
						<HomePage
							setRedirectedFromHome={setRedirectedFromHome}
						/>
					} */
				/>
				<Route
					exact
					path="/quiz"
					// element={<PrivateRoute element={<QuizPage />} />}
					element={
						redirectedFromHome ? (
							<QuizPage />
						) : (
							<Navigate to="/home" />
						)
					}
					// element={<QuizPage />}
				/>

				<Route path="/live/login" element={<LoginFFF />} />
				<Route
					exact
					path="/live/fff"
					element={
						isAuthenticated ? (
							<FFFPage />
						) : (
							<Navigate to="/live/login" />
						)
					}
					// element={<FFFPage />}
				/>
				<Route
					exact
					path="/live/kbc"
					element={
						isAuthenticated ? (
							<KBCpage />
						) : (
							<Navigate to="/live/login" />
						)
					}
				/>

				<Route
					path="/admin"
					element={isAdmin ? <AdminPage /> : <Navigate to="/home" />}
				/>
				<Route
					exact
					path="/admin/fff"
					element={
						isAdmin ? <AdminFFFPage /> : <Navigate to="/admin" />
					}
				/>
				<Route
					exact
					path="/admin/kbc"
					element={
						isAdmin ? <AdminKBCPage /> : <Navigate to="/admin" />
					}
				/>
			</Routes>
		</Router>
	);
};

export default App;
