import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import io from "socket.io-client";
import BaseURL from "../../config/baseURL";
import fffAPI from "../../config/fffAPI";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HexagonFFF from "../../components/Hexagons/hexagonFFF";
import ResultCard from "../../components/Result Cards/resultFFF";
import "./fffPage.css";
import "react-toastify/dist/ReactToastify.css";

const socket = io.connect(BaseURL.SOCKET_URL);

const FFFPage = () => {
	const [timeLeft, setTimeLeft] = useState(0);
	const [showContent, setShowContent] = useState(false);
	const [question, setQuestion] = useState([]);
	const [optionsVisible, setOptionsVisible] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState([]);
	const [answer, setAnswer] = useState(false);
	const [results, setResults] = useState(null);
	const [timer, setTimer] = useState(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [startTime, setStartTime] = useState(null);

	useEffect(() => {
		socket.on("fff-show_question", (questionData) => {
			setQuestion(questionData);
			setAnswer(false);
			setResults(false);
		});

		socket.on("fff-show_options", () => {
			setTimer(question.timer);
			setTimeLeft(question.timer); // Start the timer
			setStartTime(new Date());
			setOptionsVisible(true);
			setShowContent(true);
		});

		socket.on("fff-show_answers", () => {
			setAnswer(true);
			setResults(false);
		});

		socket.on("fff-show_result", () => {
			setResults(true);
			setAnswer(false);
		});
	}, [question]);

	useEffect(() => {
		let timer;
		if (showContent && timeLeft > 0) {
			timer = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 1000);
		} else if (timeLeft === 0 && showContent && !isSubmitted) {
			handleSubmit();
		}
		return () => clearInterval(timer);
	}, [timeLeft, showContent]);

	const showToast = (message, severity) => {
		toast(message, {
			type: severity,
			position: "top-right",
			autoClose: 4000,
			style: {
				backgroundColor: "#6a2a44",
				color: "#fff",
				top: 80,
				marginTop: 2,
			},
		});
	};

	const formatTimeTaken = (start, end) => {
		const timeDiff = end - start; // Difference in milliseconds

		const hh = String(Math.floor((timeDiff / 3600000) % 24)).padStart(
			2,
			"0"
		);
		const mm = String(Math.floor((timeDiff / 60000) % 60)).padStart(2, "0");
		const ss = String(Math.floor((timeDiff / 1000) % 60)).padStart(2, "0");
		const ms = String(timeDiff % 1000).padStart(3, "0"); // Get milliseconds part

		return `${hh}:${mm}:${ss}.${ms}`;
	};

	const handleOptionClick = (index) => {
		if (!showContent || timeLeft === 0 || isSubmitted) return;

		setSelectedOrder((prevOrder) => {
			const newOrder = [...prevOrder];
			const existingIndex = newOrder.indexOf(index);
			if (existingIndex !== -1) {
				newOrder.splice(existingIndex, 1);
			} else {
				newOrder.push(index);
			}
			return newOrder;
		});
	};

	const handleSubmit = async () => {
		if (isSubmitted) return;

		if (timeLeft && selectedOrder.length < 4) {
			showToast(
				"Please select all 4 options before submitting!",
				"error"
			);

			return;
		}

		const endTime = new Date();
		const timeTaken = formatTimeTaken(startTime, endTime);
		const answer = selectedOrder
			.map((i) => String.fromCharCode(65 + i))
			.join("");

		const data = {
			question_id: question.q_id,
			given_sequence: answer,
			time_taken: timeTaken,
		};

		try {
			// Submit the answer to the backend
			const response = await axios.post(fffAPI.SUBMIT_ANSWER_URL, data, {
				withCredentials: true,
			});

			showToast(response.data.message, "success");
			setIsSubmitted(true);
			console.log("Submitted Answer:", answer);
		} catch (error) {
			showToast("Failed to submit answer!", "error");
		}
	};

	return (
		<div>
			<Header />
			{answer ? (
				<ResultCard question={question} answer={true} result={false} />
			) : results ? (
				<div className="result-page">
					<ResultCard answer={false} result={true} />
				</div>
			) : (
				<div className="fff-container">
					<h1 className="fff-heading">Fastest Finger First</h1>
					<div className="timer-container">
						<div className="timer-wrapper">
							<svg className="timer-svg" viewBox="0 0 100 100">
								<circle
									className="timer-background"
									cx="50"
									cy="50"
									r="45"
									fill="none"></circle>
								<circle
									className="timer-progress"
									cx="50"
									cy="50"
									r="45"
									fill="none"
									strokeDasharray="283"
									strokeDashoffset={
										showContent
											? ((timer - timeLeft) / timer) * 283
											: 283
									}></circle>
								<text
									x="50%"
									y="40%"
									className="timer-text"
									dominantBaseline="middle"
									textAnchor="middle">
									{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
								</text>
								<text
									x="50%"
									y="65%"
									className="timer-label"
									dominantBaseline="middle"
									textAnchor="middle">
									seconds
								</text>
							</svg>
						</div>
					</div>

					<div className="game-content">
						<div className="question-container">
							<HexagonFFF
								QnA={
									question.question_text
										? question.question_text
										: " "
								}
								disabled={true}
								isQuestion={true}
								className={
									showContent ? "fade-in" : "empty-hexagon"
								}
							/>
						</div>

						<div className="options-container">
							{["A", "B", "C", "D"].map((letter, index) => (
								<HexagonFFF
									key={index}
									QnA={
										question && optionsVisible
											? question[
													`option_${letter.toLowerCase()}`
											  ]
											: " "
									}
									onClick={() => handleOptionClick(index)}
									selected={selectedOrder.includes(index)}
									letter={letter}
									backgroundColor={
										selectedOrder.includes(index)
											? "linear-gradient(to right, #f1c40f, #e67e22)" // Golden gradient for selected
											: "#59072b"
									} // Change background based on selection
									textColor={
										selectedOrder.includes(index)
											? "#59072b"
											: "#fff"
									}
									className={
										showContent
											? "fade-in"
											: "disabled-hexagon"
									}
								/>
							))}
						</div>

						<div className="order-submit">
							<div className="selected-order-container">
								<div className="selected-order-content">
									<h3>Selected Order:</h3>
									<div className="selected-order">
										{selectedOrder.map((index, order) => (
											<div
												key={order}
												className="order-item">
												{String.fromCharCode(
													65 + index
												)}
											</div>
										))}
									</div>
								</div>
							</div>
							<button
								className="submit-btn"
								onClick={() => setSelectedOrder([])} // Clear all selected options
								disabled={
									!showContent || !timeLeft || isSubmitted
								}
								style={{ marginRight: "20px" }} // Ensure it's closer to the selected order container
							>
								Clear All
							</button>
							<button
								className="submit-btn"
								onClick={handleSubmit}
								disabled={
									!showContent || !timeLeft || isSubmitted
								}>
								Submit
							</button>
						</div>
					</div>
				</div>
			)}
			<Footer />
		</div>
	);
};

export default FFFPage;
