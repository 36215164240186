import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import authAPI from "../../config/authAPI";
import Presents from "../../assets/logo/Presents.png";
import TKM from "../../assets/logo/Toloba-Logo.png";
import Logo from "../../assets/logo/Logo.png";
import Footer from "../../components/Footer/Footer";
import { toast } from "react-toastify";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./loginPage.css";

const LoginPage = () => {
	const [isLogin, setIsLogin] = useState(true);
	const [formData, setFormData] = useState({
		its_id: "",
		name: "",
		contact_no: "",
		city: "",
		age: "",
		gender: "",
		password: "",
		referred_by: "",
	});
    const [isLoading, setIsLoading] = useState(false);
	const toggleForm = () => setIsLogin(!isLogin);
	const navigate = useNavigate();

	// Capture referral_id from URL when page loads
	useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralIdFromUrl = urlParams.get("referral_id");
        if (referralIdFromUrl) {
            setFormData((prev) => ({
                ...prev,
                referred_by: referralIdFromUrl, // Auto-fill referral ID
            }));
            setIsLogin(false); // Switch to Sign Up mode if referral ID exists
        }
    }, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const showToast = (message, severity) => {
		toast(message, {
			type: severity,
			position: "top-left",
			autoClose: 3000,
			style: {
				backgroundColor: "#6a2a44",
				color: "#fff",
				top: 80,
				marginTop: 2,
			},
		});
	};

	const validateForm = () => {
		let isValid = true;

		if (formData.its_id && !formData.its_id.match(/^\d{8}$/)) {
			showToast("ITS ID must be 8 digits.", "error");
			isValid = false;
		}

		if (formData.contact_no && !formData.contact_no.match(/^\d{10,15}$/)) {
			showToast(
				"Enter correct contact no.\
                Include country code without +",
				"error"
			);
			isValid = false;
		}

		if (formData.password && formData.password.length < 8) {
			showToast("Password must be at least 8 characters.", "error");
			isValid = false;
		}

		if (
			formData.confirmPassword &&
			formData.password !== formData.confirmPassword
		) {
			showToast("Passwords must match.", "error");
			isValid = false;
		}

		if (formData.referred_by && formData.referred_by.length !== 9) {
			showToast("Incorrect Referral ID.", "error");
			isValid = false;
		}

		return isValid;
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (validateForm()) {
            setIsLoading(true); // Start spinner here
            try {
                if (window.grecaptcha) {
                    const token = await window.grecaptcha.execute(
                        "6LenelEqAAAAAO1hvPXWNg8WINReOxnqDiJYwbsm",
                        { action: isLogin ? "login" : "submit" }
                    );
                    
                    const payload = { ...formData, recaptchaToken: token };
                    
                    try {
                        const url = isLogin
                            ? authAPI.LOGIN_URL
                            : authAPI.REGISTER_URL;
    
                        const response = await axios.post(url, payload, {
                            withCredentials: true,
                        });
                        navigate("/auth", { replace: true });
                        window.location.reload();
                    } catch (error) {
                        if (error.response && error.response.data.message) {
                            showToast(error.response.data.message, "error");
                        } else {
                            showToast("An error occurred. Please try again later.", "error");
                        }
                    }
                }
            } catch (error) {
                showToast("reCAPTCHA error. Please try again.", "error");
            } finally {
                setIsLoading(false); // Stop spinner here
            }
        }
    };
    

	return (
		<>
			<header className="header-container">
				<img src={Logo} className="header-logo" alt="Logo" />
				<h1 className="header-login">KAUN BANEGA CHAMPION</h1>
			</header>
			<div className="page-container">
				<div className="content">
					<div className="form-section">
						<div className="form-box">
							{isLogin && (
								<div>
									<p className="form-subtitle-l">
										Welcome Back<span>!</span>
									</p>
									<p className="form-title">
										<span>Login</span> here<span>..</span>
									</p>
									<p className="form-footer">
										Don't have an account?{" "}
										<button
											className="toggle-button"
											onClick={toggleForm}>
											Sign Up
										</button>
									</p>
									<form
										className="form login-form"
										onSubmit={handleSubmit}>
										<div className="input-field">
											<input
												type="text"
												id="its_id"
												name="its_id"
												required
												onChange={handleInputChange}
												onBlur={validateForm}
												placeholder=" "
											/>
											<label
												className="input-placeholder"
												htmlFor="its_id">
												ITS ID
											</label>
										</div>
										<div className="input-field">
											<input
												type="password"
												id="password"
												name="password"
												required
												onChange={handleInputChange}
												onBlur={validateForm}
												placeholder=" "
											/>
											<label
												className="input-placeholder"
												htmlFor="password">
												Password
											</label>
										</div>
										<button
                                            type="submit"
                                            className="submit-button submit-button-l"
                                            disabled={isLoading} // Disable button while loading
                                        >
                                            {isLoading ? (
                                                <i className="fas fa-spinner fa-spin"></i> // Spinner icon
                                            ) : (
                                                "Log In"
                                            )}
                                        </button>
									</form>
								</div>
							)}

							{!isLogin && (
								<div>
									<p className="form-subtitle">
										Welcome<span>!</span>
									</p>
									<p className="form-title">
										<span>Register</span> yourself
										<span>..</span>
									</p>
									<p className="form-footer">
										Already have an account?{" "}
										<button
											className="toggle-button"
											onClick={toggleForm}>
											Log In
										</button>
									</p>
									<form
										className="form signup-form"
										onSubmit={handleSubmit}>
										<div className="input-row">
											<div className="input-field">
												<input
													type="text"
													id="its_id"
													name="its_id"
													required
													onChange={handleInputChange}
													onBlur={validateForm}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="its_id">
													ITS ID
												</label>
											</div>
											<div className="input-field">
												<input
													type="text"
													id="name"
													name="name"
													required
													onChange={handleInputChange}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="name">
													Full Name
												</label>
											</div>
										</div>

										<div className="input-row">
											<div className="input-field">
												<input
													type="text"
													id="contact_no"
													name="contact_no"
													required
													onChange={handleInputChange}
													onBlur={validateForm}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="contact_no">
													Contact No
												</label>
											</div>
											<div className="input-field">
												<input
													type="text"
													id="city"
													name="city"
													required
													onChange={handleInputChange}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="city">
													Mouze
												</label>
											</div>
										</div>

										<div className="input-row">
											<div className="input-field">
												<input
													type="text"
													id="age"
													name="age"
													required
													onChange={handleInputChange}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="age">
													Age
												</label>
											</div>
											<div className="input-field">
												<select
													name="gender"
													required
													onChange={
														handleInputChange
													}>
													<option value="">
														Select Gender
													</option>
													<option value="M">
														Male
													</option>
													<option value="F">
														Female
													</option>
												</select>
											</div>
										</div>

										<div className="input-row">
											<div className="input-field">
												<input
													type="password"
													id="password"
													required
													onChange={handleInputChange}
													onBlur={validateForm}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="password">
													Password
												</label>
											</div>
											<div className="input-field">
												<input
													type="password"
													id="cpassword"
													name="password"
													required
													onChange={handleInputChange}
													onBlur={validateForm}
													placeholder=" "
												/>
												<label
													className="input-placeholder"
													htmlFor="cpassword">
													Confirm Password
												</label>
											</div>
										</div>

										<div className="input-field long-input">
                            <input
                                type="text"
                                id="referred_by"
                                name="referred_by"
                                value={formData.referred_by} // Auto-filled from state
                                onChange={handleInputChange}
                                placeholder=" "
                            />
                            <label className="input-placeholder" htmlFor="referred_by">
                                Referral ID (Optional)
                            </label>
                        </div>
										<button
                                            type="submit"
                                            className="submit-button"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <i className="fas fa-spinner fa-spin"></i> // Spinner icon
                                            ) : (
                                                "Create Account"
                                            )}
                                        </button>
									</form>
								</div>
							)}
						</div>
					</div>

					<div className="images-section">
						<img src={TKM} alt="TKM" className="stacked-image" />
						<img
							src={Presents}
							alt="Presents"
							className="stacked-image"
						/>
						<img
							src={Logo}
							alt="Logo"
							className="stacked-image stacked-image-3"
						/>
					</div>
					<div>
						<Footer className="footer" />
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginPage;
